<template>
  <div class="ad">
    <div class="content">
      <div class="card">
        <div class="one" v-if="dataSource.length == 1">
          <div
            class="img"
            :style="{
              backgroundImage: 'url(' + dataSource[0].coverPath + ')',
              cursor:
                (dataSource[0].jumpType == 1 && dataSource[0].jumpUrl == '') ||
                (dataSource[0].jumpType == 2 && dataSource[0].resourceId == 0)
                  ? 'default'
                  : 'pointer',
            }"
            @click="jump(dataSource[0])"
          ></div>
        </div>
        <div class="carousel" v-else>
          <a-carousel autoplay arrows :dots="false">
            <template #prevArrow>
              <div class="carousel-arrow-l">
                <LeftOutlined class="icon" />
              </div>
            </template>
            <template #nextArrow>
              <div class="carousel-arrow-r">
                <RightOutlined class="icon" />
              </div>
            </template>
            <div
              class="carousel-item"
              v-for="(item, index) in dataSource"
              :key="index"
            >
              <div
                class="img"
                :style="{
                  backgroundImage: 'url(' + item.coverPath + ')',
                  cursor:
                    (item.jumpType == 1 && item.jumpUrl == '') ||
                    (item.jumpType == 2 && item.resourceId == 0)
                      ? 'default'
                      : 'pointer',
                }"
                @click="jump(item)"
              ></div>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ad",
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(_, { emit }) {
    const jump = (data) => {
      emit("jump", data);
    };
    return {
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.ad {
  .content {
    max-width: 1200px;
    margin: 0 auto 20px;
    user-select: none;
    .card {
      .one {
        width: 100%;
        height: 225px;
        .img {
          width: 100%;
          height: 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .carousel {
        width: 100%;
        height: 225px;
        &-item {
          width: 100%;
          height: 225px;
          .img {
            width: 100%;
            height: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      .carousel-arrow-l,
      .carousel-arrow-r {
        width: 36px;
        height: 36px;
        top: calc(50% - 9px);
        border-radius: 50%;
        text-align: center;
        background-color: #000;
        padding: 6px 0;
        z-index: 1;
        opacity: 0.3;
        transition: opacity 0.3s ease;
        .icon {
          color: #fff;
          font-size: 24px;
          height: 24px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      .carousel-arrow-l {
        left: 12px;
      }
      .carousel-arrow-r {
        right: 12px;
      }
    }
  }
}
</style>
